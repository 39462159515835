import { createSelector } from "reselect";
export const FederatedUsernamePrefix = "Auth0";
const FederatedShopifyUsernamePrefix = "Auth0Shopify";
export const getCognitoUsername = state => state.cognito?.user?.username;
export const getIsFederatedUser = createSelector(getCognitoUsername,
// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
username => username && username.startsWith(FederatedUsernamePrefix));
export const selectIsFederatedShopifyUser = createSelector(getCognitoUsername,
// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
username => username && username?.startsWith(FederatedShopifyUsernamePrefix));
export const getUserGroups = state => state.user.groups;
export const getIsEbayPilot = createSelector(getUserGroups,
// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
groups => groups && groups.includes("ebay"));
export const getIsAdmin = createSelector(getUserGroups,
// eslint-disable-next-line @typescript-eslint/prefer-optional-chain
groups => groups && groups.includes("admin"));
export const getShowAdminFeatures = createSelector(state => state.admin.showAdminFeatures, getIsAdmin, (showAdminFeatures, isAdmin) => isAdmin && showAdminFeatures);
export const getUserAuthState = createSelector(state => state.user, user => user?.state);
export const getSellerId = state => state.user.sellerId;
export const getIsOnWaitlist = state => state.user.isOnWaitlist;
export const getIsLinkedToFlexport = state => state.user.isLinkedToFlexport;
export const getIsPassEmailVerification = state => state.user.isPassEmailVerification;
export const getLogisticsAccountId = state => state.organization?.logisticsAccountId;
export const getIsInternationalShipping = state => state.user.isInternationalShippingEnabled;
export const getUserEmail = state => {
  return state.user.email;
};
export const getEffectiveUserEmail = state => {
  return state.cognito?.attributes?.["custom:impersonatedEmail"] || state.user.email;
};
export const selectIsOneNodeSupported = state => state.user.settings.isOneNodeSupported;
export const selectOneNodeFc = state => state.user.settings.oneNodeFc;
export const selectUserBCLPreferences = state => ({
  defaultFormat: state.user.labelPreferences.boxContentsLabels.printSize,
  defaultLanguage: state.user.labelPreferences.boxContentsLabels.language
});
export const selectUserPalletLabelPreferences = state => ({
  defaultPrintSize: state.user.labelPreferences.palletLabels.printSize,
  defaultLanguage: state.user.labelPreferences.palletLabels.language
});
export const getIsMobile = state => state.user.isMobile;
import { createSelector } from "reselect";
import { filter, map, sumBy, keys } from "lodash/fp";
import { getProductDetailsCache } from "./getProductDetailsCache";
import { selectPlanItems } from "../plan/selectPlanItems";
export const selectPlanKitProductDetails = createSelector(getProductDetailsCache, selectPlanItems, (productDetailsCache, planItems) => {
  return map(dsku => {
    const productDetail = productDetailsCache[dsku];
    const planItem = planItems[dsku];
    const totalComponentQuantity = productDetail?.kitResponse ? sumBy("qty", productDetail.kitResponse.kitComponents ?? []) : 0;
    return {
      dsku,
      name: productDetail?.name ?? "Unknown",
      totalComponentQuantity,
      qty: planItem?.qty ?? 0
    };
  }, filter(dsku => productDetailsCache[dsku]?.isKit && planItems[dsku]?.qty > 0, keys(planItems)));
});